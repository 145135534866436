/* ------------------ *\
    CORE MIXINS
\* ------------------ */
.loading-pulse {
  width: 40px;
  height: 40px;
  margin: 40px auto;
  background-color: #cccccc;
  border-radius: 100%;
  -webkit-animation: pulseScaleOut 1s infinite ease-in-out;
  animation: pulseScaleOut 1s infinite ease-in-out;
}
.loading-bounce {
  margin: 100px auto 0;
  width: 70px;
  text-align: center;
}
.loading-bounce > * {
  width: 18px;
  height: 18px;
  background-color: #cccccc;
  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}
.loading-bounce > .loading-bounce-1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
.loading-bounce > .loading-bounce-2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}
.loading-spin {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: transparent;
  display: inline-block;
  border-top: 2px solid #f5f5f5;
  border-right: 2px solid #f5f5f5;
  border-bottom: 2px solid #9f9f9f;
  border-left: 2px solid #9f9f9f;
  -webkit-animation: loadingSpin 1.2s infinite linear;
  -moz-animation: loadingSpin 1.2s infinite linear;
  -ms-animation: loadingSpin 1.2s infinite linear;
  animation: loadingSpin 1.2s infinite linear;
}
.loading-text {
  font-size: 40px;
  font-size: 2.5rem;
  margin: 40px 0;
  font-weight: 700;
  color: #cccccc;
  line-height: 100%;
  display: inline-block;
  font-size: 1.3rem;
  display: block;
  margin-top: 10px;
}
@-webkit-keyframes loadingSpin {
  to {
    -webkit-transform: rotate(360deg);
  }
}
@-moz-keyframes loadingSpin {
  to {
    -moz-transform: rotate(360deg);
  }
}
@-ms-keyframes loadingSpin {
  to {
    -ms-transform: rotate(360deg);
  }
}
@keyframes loadingSpin {
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes pulseScaleOut {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0;
  }
}
@keyframes pulseScaleOut {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0;
  }
}
@-webkit-keyframes bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
  }
}
@keyframes bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@-webkit-keyframes shake {
  10%,
  90% {
    -webkit-transform: translate3d(-1px, 0, 0);
    transform: translate3d(-1px, 0, 0);
  }
  20%,
  80% {
    -webkit-transform: translate3d(-1px, 0, 0);
    transform: translate3d(2px, 0, 0);
  }
  30%,
  50%,
  70% {
    -webkit-transform: translate3d(-1px, 0, 0);
    transform: translate3d(-4px, 0, 0);
  }
  40%,
  60% {
    -webkit-transform: translate3d(-1px, 0, 0);
    transform: translate3d(4px, 0, 0);
  }
}
@-moz-keyframes shake {
  10%,
  90% {
    -moz-transform: translate3d(-1px, 0, 0);
    transform: translate3d(-1px, 0, 0);
  }
  20%,
  80% {
    -moz-transform: translate3d(2px, 0, 0);
    transform: translate3d(2px, 0, 0);
  }
  30%,
  50%,
  70% {
    -moz-transform: translate3d(-4px, 0, 0);
    transform: translate3d(-4px, 0, 0);
  }
  40%,
  60% {
    -moz-transform: translate3d(4px, 0, 0);
    transform: translate3d(4px, 0, 0);
  }
}
@keyframes shake {
  10%,
  90% {
    -ms-transform: translate3d(-1px, 0, 0);
    -webkit-transform: translate3d(-1px, 0, 0);
    transform: translate3d(-1px, 0, 0);
  }
  20%,
  80% {
    -ms-transform: translate3d(2px, 0, 0);
    -webkit-transform: translate3d(2px, 0, 0);
    transform: translate3d(2px, 0, 0);
  }
  30%,
  50%,
  70% {
    -ms-transform: translate3d(-4px, 0, 0);
    -webkit-transform: translate3d(-4px, 0, 0);
    transform: translate3d(-4px, 0, 0);
  }
  40%,
  60% {
    -ms-transform: translate3d(4px, 0, 0);
    -webkit-transform: translate3d(4px, 0, 0);
    transform: translate3d(4px, 0, 0);
  }
}
.ie.ie8 .hidden,
.ie.ie9 .hidden {
  display: none!important;
}
.ie.ie8 #viewContent,
.ie.ie9 #viewContent {
  width: 100%;
  height: 100%;
  position: absolute;
}
@media screen and (max-width: 1440px) {
  .ie.ie8 #viewContent,
  .ie.ie9 #viewContent {
    left: 20px;
  }
}
@media screen and (max-width: 767px) {
  .ie.ie8 #viewContent,
  .ie.ie9 #viewContent {
    left: 0px;
    position: static;
  }
}
.ie.ie8 body,
.ie.ie9 body {
  padding-right: 0!important;
  padding-left: 0!important;
}
.ie.ie8 .document-info,
.ie.ie9 .document-info,
.ie.ie8 .document-edit,
.ie.ie9 .document-edit {
  padding-right: 0!important;
  left: 6px!important;
}
.ie.ie8 .header-has-vehicle .table,
.ie.ie9 .header-has-vehicle .table {
  overflow-x: visible;
}
.ie.ie8 #content {
  padding-bottom: 60px;
}
.ie.ie8 .loading-spin {
  width: 60px;
  height: 60px;
  border: none;
}
.ie.ie8 .search-results {
  position: relative;
}
.ie.ie8 .search-results .loading-spin-wrapper {
  position: absolute;
  width: 60px;
  height: 60px;
  left: 0;
  right: 0;
  margin: auto;
}
.ie.ie8 .search-results.search-results-loading .list,
.ie.ie8 .search-results.search-results-loading .pagination {
  display: none;
}
.ie.ie8 .list.list-loading:before,
.ie.ie8 .list.list-loading:after {
  content: none;
}
.ie.ie9 section > table {
  width: 100%;
}
.ie.ie8 .input-vin-group,
.ie.ie9 .input-vin-group {
  width: 360px;
}
.ie.ie8 input[type="text"].input-vin-full {
  width: 80%;
}
.ie.ie8 .input-action-searching {
  display: none!important;
}
.ie.ie8 label.input-checkbox,
.ie.ie8 label.input-radio {
  cursor: pointer;
  position: relative;
  padding-left: 30px;
  min-height: 22px;
  -webkit-transition: color ease-in 0.2s;
  -moz-transition: color ease-in 0.2s;
  -o-transition: color ease-in 0.2s;
  transition: color ease-in 0.2s;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently supported by Chrome and Opera */
  padding-top: 2px;
}
.ie.ie8 label.input-checkbox:before,
.ie.ie8 label.input-radio:before {
  content: "";
  position: absolute;
  left: 0;
  top: 5px;
  width: 15px;
  height: 15px;
  border: 1px solid #cccccc;
  background-color: #FFFFFF;
  border-radius: 0;
  -webkit-transition: border ease-in 0.2s;
  -moz-transition: border ease-in 0.2s;
  -o-transition: border ease-in 0.2s;
  transition: border ease-in 0.2s;
}
.ie.ie8 label.input-checkbox:after,
.ie.ie8 label.input-radio:after {
  content: "";
  text-align: center;
  font-family: "FontAwesome";
  position: absolute;
  top: 5px;
  left: 0;
  bottom: 0;
  margin: auto;
  font-size: 10px;
  transition: all 0.2s;
  color: #6a6a6a;
  display: none;
}
.ie.ie8 label.input-checkbox:hover:before,
.ie.ie8 label.input-radio:hover:before {
  border: 1px solid #999999 !important;
}
.ie.ie8 label.input-checkbox.input-checked,
.ie.ie8 label.input-radio.input-checked {
  color: #1b1b1b;
}
.ie.ie8 label.input-checkbox.input-checked:before,
.ie.ie8 label.input-radio.input-checked:before {
  border: 1px solid #6a6a6a;
}
.ie.ie8 label.input-checkbox.input-checked:after,
.ie.ie8 label.input-radio.input-checked:after {
  display: block;
}
.ie.ie8 label.input-checkbox.input-disabled,
.ie.ie8 label.input-radio.input-disabled {
  cursor: not-allowed;
}
.ie.ie8 label.input-checkbox.input-disabled:before,
.ie.ie8 label.input-radio.input-disabled:before {
  box-shadow: none;
  background-color: #f5f5f5;
}
.ie.ie8 label.input-checkbox.input-disabled,
.ie.ie8 label.input-radio.input-disabled,
.ie.ie8 label.input-checkbox.input-disabled:before,
.ie.ie8 label.input-radio.input-disabled:before,
.ie.ie8 label.input-checkbox.input-disabled:after,
.ie.ie8 label.input-radio.input-disabled:after {
  color: #cccccc;
}
.ie.ie8 label.input-checkbox.checkbox-indent,
.ie.ie8 label.input-radio.checkbox-indent,
.ie.ie8 label.input-checkbox.radio-indent,
.ie.ie8 label.input-radio.radio-indent {
  padding-left: 20px;
}
.ie.ie8 label.input-checkbox.checkbox-right:before,
.ie.ie8 label.input-radio.checkbox-right:before,
.ie.ie8 label.input-checkbox.radio-right:before,
.ie.ie8 label.input-radio.radio-right:before,
.ie.ie8 label.input-checkbox.checkbox-right:after,
.ie.ie8 label.input-radio.checkbox-right:after,
.ie.ie8 label.input-checkbox.radio-right:after,
.ie.ie8 label.input-radio.radio-right:after {
  right: 0;
  left: auto;
}
.ie.ie8 label.input-checkbox:after {
  content: "\f00c";
  width: 15px;
  height: 19px;
}
.ie.ie8 label.input-radio:before {
  top: 3px;
  width: 18px;
  height: 18px;
  border-radius: 50%;
}
.ie.ie8 label.input-radio:after {
  top: 6px;
  left: 5px;
  width: 8px;
  height: 8px;
  content: "";
  background: #FFFFFF;
  border-radius: 50%;
}
.ie.ie8 label.input-radio.input-checked:before {
  border: 1px solid #1b1b1b;
  background: #1b1b1b;
}
.ie.ie8 label.input-radio.input-checked:hover:before {
  border: 1px solid #1b1b1b;
}
.ie.ie8 input.checkbox[type="checkbox"],
.ie.ie8 input.radio[type="checkbox"],
.ie.ie8 input.checkbox[type="radio"],
.ie.ie8 input.radio[type="radio"] {
  position: fixed;
  left: -9999px;
}
.ie.ie8 .select select,
.ie.ie9 .select select {
  padding-right: 10px;
}
.ie.ie8 .select:before,
.ie.ie9 .select:before,
.ie.ie8 .select:after,
.ie.ie9 .select:after {
  content: none!important;
}
.ie.ie8 ul.tabs li {
  float: left;
  margin-right: 3px;
}
.ie.ie8 ul.tabs:after {
  content: " ";
  display: table;
  clear: both;
}
.ie.ie8 .snackbar-pos.top-center {
  bottom: auto !important;
  top: -80px;
  left: 50%;
  width: 500px;
  margin-left: -250px;
}
.ie.ie9 .snackbar-pos.top-center {
  bottom: auto !important;
  top: -50px;
  left: 50%;
  width: 500px;
  margin-left: -250px;
}
.ie.ie8 .arc-snackbar,
.ie.ie9 .arc-snackbar {
  width: 500px!important;
}
@media only screen and (max-width: 500px) {
  .ie.ie8 .arc-snackbar,
  .ie.ie9 .arc-snackbar {
    width: 100%!important;
  }
}
.ie.ie8 #help-content .button.disabled h1,
.ie.ie8 #help-content .button.disabled h2,
.ie.ie8 #help-content .button.disabled h3,
.ie.ie8 #help-content .button.disabled h4,
.ie.ie8 #help-content .button.disabled h5,
.ie.ie8 #help-content .button.disabled h6,
.ie.ie8 #help-content .button.disabled span,
.ie.ie8 #help-content .button.disabled i.fa,
.ie.ie8 #help-content .button.disabled p {
  color: #e6e6e6 !important;
}
.ie.ie8 #help-content .button.disabled .button-text-overlay {
  color: #3E3E3E !important;
}
.ie.ie8 .notification .notification-icon > i.fa {
  padding-left: 10px;
}
.ie.ie8 .notification-centre-trigger .notification-centre-trigger-bell,
.ie.ie9 .notification-centre-trigger .notification-centre-trigger-bell,
.ie.ie8 .notification-centre-trigger .notification-centre-trigger-counter,
.ie.ie9 .notification-centre-trigger .notification-centre-trigger-counter {
  position: relative;
  top: -1px;
}
.ie.ie8 .notification-centre-trigger-mobile .notification-centre-trigger-bell,
.ie.ie9 .notification-centre-trigger-mobile .notification-centre-trigger-bell {
  position: static;
}
.ie.ie8 .notification-centre-trigger-mobile .notification-centre-trigger-counter,
.ie.ie9 .notification-centre-trigger-mobile .notification-centre-trigger-counter {
  position: absolute;
  top: 10px;
}
.ie.ie8 .notification-centre .notification-centre-body .notification-centre-content .notification .notification-header .notification-title,
.ie.ie9 .notification-centre .notification-centre-body .notification-centre-content .notification .notification-header .notification-title {
  word-break: break-all;
}
.ie.ie8 .notification-centre .notification-centre-body .notification-centre-content .notification .notification-body,
.ie.ie9 .notification-centre .notification-centre-body .notification-centre-content .notification .notification-body {
  word-break: break-all;
}
.ie.ie8 .notification-centre-trigger-mobile {
  text-align: right;
}
.ie.ie8 .notification-centre-trigger-mobile .notification-centre-trigger-bell {
  width: 50px!important;
}
.ie.ie8 .notification-centre-trigger-mobile .notification-centre-trigger-counter {
  top: 5px;
}
