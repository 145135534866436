&.ie8 {
    .snackbar-pos.top-center {
        bottom: auto !important;
        top: -80px;
        left: 50%;
        width: 500px;
        margin-left: -250px;
    }
}

&.ie9 {
    .snackbar-pos.top-center {
        bottom: auto !important;
        top: -50px;
        left: 50%;
        width: 500px;
        margin-left: -250px;
    }
}

&.ie8, &.ie9 {
	.arc-snackbar {
		width: 500px!important;
	}
} 

@media only screen and (max-width: 500px) {
	&.ie8, &.ie9 {
		.arc-snackbar {
			width: 100%!important;
		}
	}
}
