/* ------------------ *\
    CORE MIXINS
\* ------------------ */

.responsive(@type, @rules, @max:true) {
  & when (@max = true) {
    @media only screen and (max-width: @type) {
      @rules();
    }
  }

  & when (@max = false) {
    @media only screen and (min-width: (@type + 1)) {
      @rules();
    }
  }
}

.clearfix() {
  &:after {
    content: '';
    display: table;
    clear: both;
  }
}

.no-highlight() {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome and Opera */
}

// CSS3 PROPERTIES
// --------------------------------------------------

/// prefix box-shadow
/// @param @boxShadow - box shadow to prefix
/// @usage @include border-radius(10px 10px 5px 0px rgba(0,0,0,0.75));
.box-shadow(@boxShadow) {
  -webkit-box-shadow: @boxShadow;
  -moz-box-shadow: @boxShadow;
  box-shadow: @boxShadow;
}

/// prefix box-sizing
/// @param @type: border-box (default) - box size value to prefix
/// @usage @include border-radius(content-box);
.box-sizing(@type: border-box) {
  -webkit-box-sizing: @type;
  -moz-box-sizing: @type;
  box-sizing: @type;
}

// Transitions
.transition(@transition) {
  -webkit-transition: @transition;
  -moz-transition: @transition;
  -o-transition: @transition;
  transition: @transition;
}

.transition-swift(@type: all, @duration: 0.3s) {
  .transition(@type @duration ~'cubic-bezier(.25,.8,.25,1)');
}

// Opacity
.opacity(@opacity) {
  opacity: @opacity / 100;
  filter: ~'alpha(opacity=@{opacity})';
}

.transform(@transform) {
  -ms-transform: @transform;
  -webkit-transform: @transform;
  transform: @transform;
}

// Border Radius
.border-radius(@radius) {
  -webkit-border-radius: @radius;
  -moz-border-radius: @radius;
  border-radius: @radius;
}

// Single Corner Border Radius
.border-top-left-radius(@radius) {
  -webkit-border-top-left-radius: @radius;
  -moz-border-radius-topleft: @radius;
  border-top-left-radius: @radius;
}

.border-top-right-radius(@radius) {
  -webkit-border-top-right-radius: @radius;
  -moz-border-radius-topright: @radius;
  border-top-right-radius: @radius;
}

.border-bottom-right-radius(@radius) {
  -webkit-border-bottom-right-radius: @radius;
  -moz-border-radius-bottomright: @radius;
  border-bottom-right-radius: @radius;
}

.border-bottom-left-radius(@radius) {
  -webkit-border-bottom-left-radius: @radius;
  -moz-border-radius-bottomleft: @radius;
  border-bottom-left-radius: @radius;
}

// Single Side Border Radius
.border-top-radius(@radius) {
  .border-top-right-radius(@radius);
  .border-top-left-radius(@radius);
}

.border-right-radius(@radius) {
  .border-top-right-radius(@radius);
  .border-bottom-right-radius(@radius);
}

.border-bottom-radius(@radius) {
  .border-bottom-right-radius(@radius);
  .border-bottom-left-radius(@radius);
}

.border-left-radius(@radius) {
  .border-top-left-radius(@radius);
  .border-bottom-left-radius(@radius);
}

.watermark() {
  font-size: 40px;
  font-size: 2.5rem;
  margin: @dsu 0;
  font-weight: @font-weight-bold;
  color: @colour-light-gray;
  line-height: 100%;
  display: inline-block;
}

.loading-pulse() {
  width: 40px;
  height: 40px;
  margin: 40px auto;
  background-color: @colour-light-gray;
  border-radius: 100%;
  -webkit-animation: pulseScaleOut 1s infinite ease-in-out;
  animation: pulseScaleOut 1s infinite ease-in-out;
}

.loading-pulse {
  .loading-pulse();
}

.loading-bounce() {
  margin: 100px auto 0;
  width: 70px;
  text-align: center;

  > * {
    width: 18px;
    height: 18px;
    background-color: @colour-light-gray;
    border-radius: 100%;
    display: inline-block;
    -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
    animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  }

  > .loading-bounce-1 {
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
  }

  > .loading-bounce-2 {
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
  }
}

.loading-spin() {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: transparent;
  display: inline-block;
  border-top: 2px solid @colour-white-gray;
  border-right: 2px solid @colour-white-gray;
  border-bottom: 2px solid #9f9f9f;
  border-left: 2px solid #9f9f9f;
  -webkit-animation: loadingSpin 1.2s infinite linear;
  -moz-animation: loadingSpin 1.2s infinite linear;
  -ms-animation: loadingSpin 1.2s infinite linear;
  animation: loadingSpin 1.2s infinite linear;
}

.loading-spin-icon() {
  .loading-spin();

  width: 18px;
  height: 18px;
}

.loading-bounce {
  .loading-bounce();
}

.loading-spin {
  .loading-spin();
}

.loading-text {
  .watermark();

  font-size: 1.3rem;
  display: block;
  margin-top: @hsu;
}

// Keyframes

@-webkit-keyframes loadingSpin {
  to {
    -webkit-transform: rotate(360deg);
  }
}

@-moz-keyframes loadingSpin {
  to {
    -moz-transform: rotate(360deg);
  }
}

@-ms-keyframes loadingSpin {
  to {
    -ms-transform: rotate(360deg);
  }
}

@keyframes loadingSpin {
  to {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes pulseScaleOut {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0;
  }
}

@keyframes pulseScaleOut {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0;
  }
}

@-webkit-keyframes bouncedelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
  }

  40% {
    -webkit-transform: scale(1);
  }
}

@keyframes bouncedelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@-webkit-keyframes shake {
  10%, 90% {
    -webkit-transform: translate3d(-1px, 0, 0);
    transform: translate3d(-1px, 0, 0);
  }

  20%, 80% {
    -webkit-transform: translate3d(-1px, 0, 0);
    transform: translate3d(2px, 0, 0);
  }

  30%, 50%, 70% {
    -webkit-transform: translate3d(-1px, 0, 0);
    transform: translate3d(-4px, 0, 0);
  }

  40%, 60% {
    -webkit-transform: translate3d(-1px, 0, 0);
    transform: translate3d(4px, 0, 0);
  }
}

@-moz-keyframes shake {
  10%, 90% {
    -moz-transform: translate3d(-1px, 0, 0);
    transform: translate3d(-1px, 0, 0);
  }

  20%, 80% {
    -moz-transform: translate3d(2px, 0, 0);
    transform: translate3d(2px, 0, 0);
  }

  30%, 50%, 70% {
    -moz-transform: translate3d(-4px, 0, 0);
    transform: translate3d(-4px, 0, 0);
  }

  40%, 60% {
    -moz-transform: translate3d(4px, 0, 0);
    transform: translate3d(4px, 0, 0);
  }
}

@keyframes shake {
  10%, 90% {
    .transform(~'translate3d(-1px, 0, 0)');
  }

  20%, 80% {
    .transform(~'translate3d(2px, 0, 0)');
  }

  30%, 50%, 70% {
    .transform(~'translate3d(-4px, 0, 0)');
  }

  40%, 60% {
    .transform(~'translate3d(4px, 0, 0)');
  }
}
