&.ie8, &.ie9 {
    .notification-centre-trigger {
        .notification-centre-trigger-bell, .notification-centre-trigger-counter {
            position: relative;
            top: -1px;
        }
    }

    .notification-centre-trigger-mobile {
        .notification-centre-trigger-bell {
            position: static;
        }

        .notification-centre-trigger-counter {
            position: absolute;
            top: @hsu;
        }
    }

    .notification-centre {
        .notification-centre-body {
            .notification-centre-content {
                .notification {
                    .notification-header {
                        .notification-title {
                            word-break: break-all;
                        }
                    }

                    .notification-body {
                        word-break: break-all;
                    }
                }
            }
        }
    }
}

&.ie8 {
    .notification-centre-trigger-mobile {
        text-align: right;

        .notification-centre-trigger-bell {
            width: 50px!important;
        }

        .notification-centre-trigger-counter {
            top: @qsu;
        }
    }
}
