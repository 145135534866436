&.ie8, &.ie9 {
    .hidden {
        display: none!important;
    }

    #viewContent {
        width: 100%;
        height: 100%;
        position: absolute;

        @media screen and (max-width: 1440px) {
            left: 20px;
        }

        @media screen and (max-width: 767px) {
            left: 0px;
            position: static;
        }
    }

    body {
        padding-right: 0!important;
        padding-left: 0!important;
    }


    .document-info, .document-edit {
        padding-right: 0!important;
        left: 6px!important;
    }

    // To fix TOPIX-4853 in IE8, which broke because of TOPIX-4574.
    .header-has-vehicle .table {
        overflow-x: visible;
    }
}

&.ie8 {
    #content {
        padding-bottom: @dsu + @bsu;
    }

    .loading-spin {
        width: 60px;
        height: 60px;
        border: none;
    }

    .search-results {
        position: relative;

        .loading-spin-wrapper {
            position: absolute;
            width: 60px;
            height: 60px;
            left: 0;
            right: 0;
            margin: auto;
        }

        &.search-results-loading {
            .list, .pagination {
                display: none;
            }
        }
    }

    .list.list-loading {
        &:before, &:after {
            content: none;
        }
    }
}

&.ie9 {

    section > table {
        width: 100%;
    }

}
