&.ie8, &.ie9 {
    .select {
        select {
            padding-right: 10px;
        }

        &:before, &:after {
            content: none!important;
        }
    }
}
