&.ie8 {
    .notification {

        // fix alignment issue
        .notification-icon {
            > i.fa {
                padding-left: @hsu;
            }
        }
    }
}
