&.ie8 {
    #help-content {
        .button.disabled {
            h1, h2, h3, h4, h5, h6, span, i.fa, p {
                color: @colour-lighter-gray!important;
            }

            .button-text-overlay {
                color: @colour-dark-gray!important;
            }
        }
    }
}
