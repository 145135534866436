&.ie8 {

    ul.tabs {
        li {
            float: left;
            margin-right: 3px;
        }

        &:after {
            content: " ";
            display: table;
            clear: both;
        }
    }

}
