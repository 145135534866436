&.ie8, &.ie9 {
    .input-vin-group {
        width: 360px;
    }
}

&.ie8 {
    input[type="text"].input-vin-full {
        width: 80%;
    }

    .input-action-searching {
        display: none!important;
    }

    label.input-checkbox, label.input-radio {
        cursor: pointer;
        position: relative;
        padding-left: 30px;
        min-height: 22px;
        .transition(color ease-in .2s);
        .no-highlight();
        padding-top: 2px;

        &:before {
            content: "";
            position: absolute;
            left: 0;
            top: 5px;
            width: 15px;
            height: 15px;
            border: 1px solid @colour-light-gray;
            background-color: @colour-white;
            border-radius: 0;
            .transition(border ease-in .2s);
        }

        &:after {
            content: "";
            text-align: center;
            font-family: "FontAwesome";
            position: absolute;
            top: 5px;
            left: 0;
            bottom: 0;
            margin: auto;
            font-size: 10px;
            transition: all 0.2s;
            color: @colour-gray;

            // not checked - default state
            display: none;
        }

        &:hover:before {
            border: 1px solid darken(@colour-light-gray, 20%)!important;
        }

        &.input-checked {
            color: @colour-accent;

            &:before {
                border: 1px solid @colour-gray;
            }

            &:after {
                display: block;
            }
        }

        &.input-disabled {
            cursor: not-allowed;

            &:before {
                box-shadow: none;
                background-color: @colour-white-gray;
            }

            &, &:before, &:after {
                color: @colour-light-gray;
            }
        }


        &.checkbox-indent, &.radio-indent {
            padding-left: @bsu;
        }

        &.checkbox-right, &.radio-right {
            &:before, &:after {
                right: 0;
                left: auto;
            }
        }
    }

    label.input-checkbox {
        &:after {
            content: "\f00c";
            width: 15px;
            height: 19px;
        }
    }

    label.input-radio {
        &:before{
            top: 3px;
            width: 18px;
            height: 18px;
            border-radius: 50%;
        }

        &:after {
            top: 6px;
            left: 5px;
            width: 8px;
            height: 8px;
            content: "";
            background: @colour-white;
            border-radius: 50%;
        }

        &.input-checked {
            &:before {
                border: 1px solid @colour-accent;
                background: @colour-accent;
            }

            &:hover:before {
                border: 1px solid @colour-accent;
            }
        }
    }

    input {
        &.checkbox, &.radio {
            &[type="checkbox"], &[type="radio"] {
                position: fixed;
                left: -9999px;
            }
        }
    }
}
